// src/components/Subscription.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { auth } from '../firebase';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function CheckoutForm({ handleSubscriptionUpdate }) {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [showCardField, setShowCardField] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      setError(error.message);
      setProcessing(false);
    } else {
      const token = await auth.currentUser.getIdToken();
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/create-subscription`,
          { paymentMethodId: paymentMethod.id },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.status === 'success') {
          handleSubscriptionUpdate('paid');
        } else {
          setError('Subscription failed. Please try again.');
        }
      } catch (err) {
        setError('An error occurred. Please try again.');
      }
      setProcessing(false);
    }
  };

  return (
    <div>
      {!showCardField ? (
        <button
          onClick={() => setShowCardField(true)}
          className="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-orange-400 to-yellow-400 hover:from-orange-500 hover:to-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
        >
          Upgrade to Paid
        </button>
      ) : (
        <form onSubmit={handleSubmit}>
          <CardElement />
          {error && <div className="text-red-500 mt-2">{error}</div>}
          <button
            type="submit"
            disabled={!stripe || processing}
            className="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-orange-400 to-yellow-400 hover:from-orange-500 hover:to-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {processing ? 'Processing...' : 'Subscribe'}
          </button>
        </form>
      )}
      <p className="mt-4 text-sm text-gray-600">
        Upgrade to paid for unlimited use of our email fixing service. Your subscription will auto-renew monthly.
      </p>
    </div>
  );
}

function Subscription() {
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [remainingFixes, setRemainingFixes] = useState(null);
  const [currentPeriodEnd, setCurrentPeriodEnd] = useState(null);
  const [cancelAtPeriodEnd, setCancelAtPeriodEnd] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchSubscriptionStatus();
  }, []);

  const fetchSubscriptionStatus = async () => {
    const token = await auth.currentUser.getIdToken();
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/account-info`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSubscriptionStatus(response.data.subscription_status);
      setRemainingFixes(response.data.remaining_fixes);
      setCurrentPeriodEnd(response.data.current_period_end);
      setCancelAtPeriodEnd(response.data.subscription_status === 'cancelling');
    } catch (error) {
      console.error('Error fetching subscription status:', error);
      setError('Failed to fetch subscription status');
    }
  };

  const handleSubscriptionUpdate = (newStatus) => {
    setSubscriptionStatus(newStatus);
    fetchSubscriptionStatus(); // Refresh all subscription data
  };

  const handleCancelSubscription = async () => {
    if (window.confirm('Are you sure you want to cancel your subscription?')) {
      setIsLoading(true);
      const token = await auth.currentUser.getIdToken();
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/cancel-subscription`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.status === 'success') {
          fetchSubscriptionStatus(); // Refresh subscription info
        } else {
          setError('Failed to cancel subscription');
        }
      } catch (error) {
        console.error('Error cancelling subscription:', error);
        setError('Failed to cancel subscription');
      }
      setIsLoading(false);
    }
  };

  const handleResubscribe = async () => {
    setIsLoading(true);
    const token = await auth.currentUser.getIdToken();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/resubscribe-subscription`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status === 'success') {
        fetchSubscriptionStatus(); // Refresh subscription info
      } else {
        setError('Failed to reactivate subscription');
      }
    } catch (error) {
      console.error('Error reactivating subscription:', error);
      setError('Failed to reactivate subscription');
    }
    setIsLoading(false);
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md mx-auto">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Subscription
        </h2>
        
        {error && (
          <div className="mt-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <span className="block sm:inline">{error}</span>
          </div>
        )}

        <div className="mt-8 bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="text-center">
            <p className="text-sm font-medium text-gray-700">Current subscription status</p>
            
            {subscriptionStatus === 'Trial' && (
              <>
                <p className="mt-1 text-3xl font-extrabold text-gray-900">Trial</p>
                {remainingFixes !== null && (
                  <p className="mt-2 text-sm text-gray-600">
                    Remaining fixes: {remainingFixes}
                  </p>
                )}
              </>
            )}
            
            {subscriptionStatus === 'active' && (
              <>
                <p className="mt-1 text-3xl font-extrabold text-gray-900">Active</p>
                {currentPeriodEnd && (
                  <p className="mt-2 text-sm text-gray-600">
                    Auto-renews on: {new Date(currentPeriodEnd * 1000).toLocaleDateString()}
                  </p>
                )}
              </>
            )}
            
            {subscriptionStatus === 'cancelling' && (
              <>
                <p className="mt-1 text-3xl font-extrabold text-gray-900">Active</p>
                {currentPeriodEnd && (
                  <div>
                    <p className="mt-2 text-sm text-gray-600">
                      Your subscription will be cancelled on: {new Date(currentPeriodEnd * 1000).toLocaleDateString()}
                    </p>
                    <p className="mt-2 text-sm text-gray-600">
                      You still have access to all features until this date.
                    </p>
                  </div>
                )}
              </>
            )}

            {subscriptionStatus === 'inactive' && (
              <p className="mt-1 text-3xl font-extrabold text-gray-900">Inactive</p>
            )}

            {subscriptionStatus === 'Active (forever)' && (
              <p className="mt-1 text-3xl font-extrabold text-gray-900">Active (forever)</p>
            )}
          </div>

          {subscriptionStatus === 'Trial' && (
            <div className="mt-8">
              <Elements stripe={stripePromise}>
                <CheckoutForm handleSubscriptionUpdate={handleSubscriptionUpdate} />
              </Elements>
            </div>
          )}

          {subscriptionStatus === 'active' && (
            <div className="mt-8">
              <button
                onClick={handleCancelSubscription}
                disabled={isLoading}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-300 hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400"
              >
                {isLoading ? 'Processing...' : 'Cancel Subscription'}
              </button>
            </div>
          )}

          {subscriptionStatus === 'cancelling' && (
            <div className="mt-8">
              <button
                onClick={handleResubscribe}
                disabled={isLoading}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                {isLoading ? 'Processing...' : 'Reactivate Subscription'}
              </button>
            </div>
          )}

          {subscriptionStatus === 'inactive' && (
            <div className="mt-8">
              <Elements stripe={stripePromise}>
                <CheckoutForm handleSubscriptionUpdate={handleSubscriptionUpdate} />
              </Elements>
            </div>
          )}

          {subscriptionStatus === 'Active (forever)' && (
            <div className="mt-8 bg-green-50 border-l-4 border-green-400 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-sm text-green-700">
                    You have a lifetime subscription. Enjoy unlimited access to all features!
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Subscription;