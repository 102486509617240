// src/components/AccountInfo.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { auth } from '../firebase';

function AccountInfo() {
  const [accountInfo, setAccountInfo] = useState(null);
  const [error, setError] = useState('');
  const [remainingFixes, setRemainingFixes] = useState(null);

  const fetchAccountInfo = async () => {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/account-info`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAccountInfo(response.data);
      setRemainingFixes(response.data.remaining_fixes);  // Use the value from the backend
    } catch (error) {
      setError('Failed to fetch account information. Please try again later.');
      console.error('Error fetching account info:', error);
    }
  };

  useEffect(() => {
    fetchAccountInfo();
  }, []);

  const clearFixedEmails = async () => {
    try {
      const token = await auth.currentUser.getIdToken();
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/clear-fixed-emails`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Refresh account info after clearing emails
      fetchAccountInfo();
    } catch (error) {
      setError('Failed to clear fixed emails. Please try again later.');
      console.error('Error clearing fixed emails:', error);
    }
  };

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!accountInfo) {
    return <div>Loading account information...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Account Information
        </h2>
      </div>
  
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <dl className="space-y-6">
            <div>
              <dt className="text-sm font-medium text-gray-500">Email</dt>
              <dd className="mt-1 text-sm text-gray-900">{accountInfo.email}</dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">Subscription Status</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {accountInfo.subscription_status === 'Trial' && 'Trial'}
                {accountInfo.subscription_status === 'active' && 'Active'}
                {accountInfo.subscription_status === 'cancelling' && 'Active'}
                {accountInfo.subscription_status === 'inactive' && 'Inactive'}
                {accountInfo.subscription_status === 'Active (forever)' && 'Active (forever)'}
              </dd>
            </div>
            {accountInfo.subscription_status === 'free' && (
              <div>
                <dt className="text-sm font-medium text-gray-500">Remaining Email Fixes</dt>
                <dd className="mt-1 text-sm text-gray-900">{3 - accountInfo.email_fixes_count}</dd>
              </div>
            )}
          </dl>
  
          <div className="mt-8">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Recent Fixed Emails</h3>
            <button
              onClick={clearFixedEmails}
              className="mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-300 hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Clear Recent Fixed Emails
            </button>
            {accountInfo.fixed_emails.length > 0 ? (
              <ul className="mt-4 space-y-6">
                {accountInfo.fixed_emails.map((email, index) => (
                  <li key={index} className="bg-gray-50 p-4 rounded-md">
                    <h4 className="text-sm font-medium text-black-500">Original Email:</h4>
                    <pre className="mt-1 text-sm text-gray-900 whitespace-pre-wrap">{email.original}</pre>
                    <h4 className="mt-4 text-sm font-medium text-black-500">Fixed Email:</h4>
                    <pre className="mt-1 text-sm text-gray-900 whitespace-pre-wrap">{email.fixed}</pre>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="mt-4 text-sm text-gray-500">No fixed emails yet.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountInfo;
