import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { auth } from '../firebase';
import { Link, useNavigate } from 'react-router-dom';

function EmailFixer() {
  const [emailContent, setEmailContent] = useState('');
  const [fixedEmail, setFixedEmail] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [remainingFixes, setRemainingFixes] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState('free');
  const navigate = useNavigate();

  useEffect(() => {
    fetchAccountInfo();
  }, []);

  const fetchAccountInfo = async () => {
    const token = await auth.currentUser.getIdToken();
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/account-info`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('Account info:', response.data);
      setSubscriptionStatus(response.data.subscription_status);
      setRemainingFixes(response.data.remaining_fixes);
    } catch (error) {
      console.error('Error fetching account info:', error);
    }
  };

  const handleFixEmail = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/fix-email`,
        { content: emailContent },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFixedEmail(response.data.fixed);
      // Update remaining fixes count
      if (subscriptionStatus === 'free' && remainingFixes !== null) {
        setRemainingFixes(prevFixes => Math.max(0, prevFixes - 1));
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setError('You have reached your free quota. Please upgrade to continue.');
        setRemainingFixes(0);
      } else {
        setError('An error occurred while fixing the email. Please try again.');
      }
      console.error('Error fixing email:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8 pt-16 w-full">
      <div className="max-w-md mx-auto space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Email Fixer
          </h2>
          {subscriptionStatus === 'free' && remainingFixes !== null && (
            <p className="mt-2 text-center text-sm text-gray-600">
              Remaining fixes: {remainingFixes}
            </p>
          )}
        </div>
        <form onSubmit={handleFixEmail} className="mt-8 space-y-6">
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="emailContent" className="sr-only">
                Enter your email content:
              </label>
              <textarea
                id="emailContent"
                value={emailContent}
                onChange={(e) => setEmailContent(e.target.value)}
                rows="10"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 focus:z-10 sm:text-sm"
                placeholder="Enter your email content here..."
              />
            </div>
          </div>
          {/* Add the submit button here */}
          <div>
            <button
              type="submit"
              disabled={isLoading || (subscriptionStatus === 'free' && remainingFixes === 0)}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gradient-to-r from-orange-400 to-yellow-400 hover:from-orange-500 hover:to-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 disabled:opacity-50 disabled:cursor-not-allowed"
             >
              {isLoading ? 'Fixing...' : 'Fix Email'}
            </button>
          </div>
        </form>
        {/* Quota reached message and upgrade link */}
        {subscriptionStatus === 'free' && remainingFixes === 0 && (
          <div className="mt-4 text-center">
            <p className="text-sm text-gray-600">
              You've reached your free quota. 
              <Link to="/subscription" className="ml-1 font-medium text-orange-600 hover:text-orange-500">
                Upgrade your subscription
              </Link> {' '}
              to continue fixing emails.
            </p>
          </div>
        )}

        {/* Error message */}
        {error && (
          <div className="rounded-md bg-red-50 p-4 mt-4">
            <div className="flex">
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">Error</h3>
                <div className="mt-2 text-sm text-red-700">
                  <p>{error}</p>
                </div>
              </div>
            </div>
          </div>
        )}

        {fixedEmail && (
          <div className="mt-8 bg-white overflow-hidden shadow rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <div className="flex justify-between items-center">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Fixed Email:</h3>
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(fixedEmail);
                    // Optionally, you can add some visual feedback here
                  }}
                  className="px-3 py-1 text-sm font-medium text-white bg-orange-300 rounded-md hover:bg-orange-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-300 flex items-center"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                  </svg>
                  Copy
                </button>
              </div>
              <div className="mt-5 bg-gray-50 p-4 rounded-md">
                <pre className="text-sm text-gray-700 whitespace-pre-wrap">{fixedEmail}</pre>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default EmailFixer;