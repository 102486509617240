// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDT8f8FFf-Otf11a6ZsPi36n0MqUOw6ocE",
    authDomain: "emailbao-phph.firebaseapp.com",
    projectId: "emailbao-phph",
    storageBucket: "emailbao-phph.appspot.com",
    messagingSenderId: "1067695175102",
    appId: "1:1067695175102:web:e6d190102f4a40f733b5ef",
    measurementId: "G-73S80MSRF5"
  };

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
